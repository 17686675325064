import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({

    bolderStyle:{
        fontFamily:'Helvetica-Bold'
    },

    blockHeaderStyle:{
        width:"100%",
        display: "flex",
        flexDirection: "row",
        fontFamily:'Helvetica-Bold'
    },

    CompanyTitleStyle:{
        fontSize: "40px",
        color: "gray", 
        left: "20px",
        top:"8px" ,
        marginBottom:"15px"
        
    },
    TypeInvoiceTitle:{
       fontSize: "15px", 
       color: "black", 
       left:"82px",
       top:"15px" ,  
    },
    PageInfoStyle:{
        fontSize: "10px", 
        color: "black",
        top:"10px",
        left:"60px"
    },
    blockHeader:{
        width:"33.33%",
    },
    block50Percent:{
        width:"50%",
    },
    block30Percent:{
        width:"30%",
    },
    block70Percent:{
        width:"70%",
    },
    HeaderTextStyle:{
        fontSize: "8px", 
        left: "33px",
        fontFamily:'Helvetica-Bold'
    },
    ColumnaHeader2: {
        width: "45%",
        fontSize: "8px",
        textAlign: "left",
        display: "block",
        color: "#000000",
        fontFamily:'Helvetica-Bold'
    },
    ColumnaHeader1: {
        width: "40%",
        fontSize: "8px",
        textAlign: "left",
        display: "block",
        color: "#000000",
        fontFamily:'Helvetica-Bold'
    },
    SubContainerHeader: {
        left: "33px",
        width: "70%",
        marginRight: "35%",
        display: "flex",
        flexDirection: "row",
        marginTop: "2px",
        color: "rgb(255, 255, 255)",
        fontFamily:'Helvetica-Bold'

    },
    TitleBoxes1:{
        fontSize: "11px",
        right:"35px",
        marginBottom:"2px",
        marginTop:"5px"
    },
    TitleBoxes2:{
        fontSize: "11px",
        left:"25px",
        marginBottom:"2px",
        marginTop:"5px"
    },
    BorderBox:{
        border: '2px',
        borderColor: 'black',
        borderStyle: 'solid', 
    },
    BusinessLogo: {
        width: "200px",
        height: "60px",
        top: "10px",
        marginBottom:"10px"
    },

    BoxLeft: {
        width:"30%",
        border: '2px',
        borderColor: 'black',
        borderStyle: 'solid',
        minHeight: '10px',
        minWidth: '225px',
        
    },
    Space:{
        marginBottom:"5px"
    },
    BoxRight: {
        width:"30%",
        border: '2px',
        borderColor: 'black',
        borderStyle: 'solid',
        minHeight: '10px',
        minWidth: '225px',
        marginLeft:"62px"
    },

    TextStyleInHeaderBoxes:{
        fontSize: "11px",
        marginLeft:"3px",
        fontFamily:'Helvetica-Bold'      
    },
    Table1Style:{
        marginLeft:"6%",
        marginRight:"6%",
        marginTop:"2px",
        width:"88%",
        borderColor: 'black',
        borderStyle: 'solid',
        borderBottom: '1.5px',
        borderTop:'1.5px',
        fontSize: "11px",
    },
    block22Percent:{
        width:"22%",
        display: "block",
        marginBottom:"5px"
    },
    block22PercentHeader:{
        width:"22%",
        display: "block",
        fontFamily:'Helvetica-Bold'
        
    },
    Table1File:{
        width:"100%",  
        display: "flex",
        flexDirection: "row",
        
    },
    Table: {
        width: "88%",
        marginLeft: "6%",
        marginTop: "9px",
        
    },

    CabeceraTabla: {
        width: "100%",
        backgroundColor: "#FFFFFF",
        color: "#000000",
        textAlign: "center",
        display: "flex",
        flexDirection: "row",
        borderBottom: '1px',
        borderTop:'1px',
        borderRight:'1px',
        borderColor: 'black',
        borderStyle: 'solid',
        height: 40
    },
    ColumnaCantidadesHeader: {

        width: "7%",
        fontSize: "9.5px",
        textAlign: "center",
        display: "block",
        borderLeft: '1px',
        borderColor: 'black',
        borderStyle: 'solid',
        fontFamily:'Helvetica-Bold'
   
    },
    ColumnaCantidades: {
        width: "7%",
        fontSize: "9.5px",
        textAlign: "center",
        display: "block",
        borderLeft: '1px',
        borderBottom:'1px',
        borderStyle: 'solid',
        
    },
    ColumnaCantidadesClear: {
        width: "7%",
        fontSize: "9.5px",
        textAlign: "center",
        display: "block",
        borderLeft: '1px',
        borderColor: 'black',
        borderStyle: 'solid',
        
    },
    ColumnaCantidadesBottom: {
        width: "7%",
        fontSize: "9.5px",
        textAlign: "center",
        display: "block",

    },
    ColumnaNormalHeader: {
        fontSize: "9.5px",
        width: "18%",
        borderLeft: '1px',
        textAlign: "center",
        borderStyle: 'solid', 
        display: "block",
        fontFamily:'Helvetica-Bold'

    },
    ColumnaPequenaCenterHeader: {
        fontSize: "9.5px",
        width: "10%",
        borderLeft: '1px',
        textAlign: "center",
        borderStyle: 'solid', 
        display: "block",
        fontFamily:'Helvetica-Bold'

    },
    ColumnaNormalLeftHeader: {
        fontSize: "9.5px",
        width: "18%",
        textAlign: "center",
        borderStyle: 'solid', 
        display: "block",
        borderLeft: '1px',
        fontFamily:'Helvetica-Bold',
       

    },
    ColumnaNormal: {
        fontSize: "9.5px",
        width: "18%",
        textAlign: "left",
        display: "block",
       
        borderLeft:"1px",
        borderBottom:"1px",
        borderStyle: 'solid'
 
    },
    ColumnaPequenaCenter: {
        fontSize: "9.5px",
        width: "10%",
        textAlign: "center",
        display: "block",
       
        borderLeft:"1px",
        borderBottom:"1px",
        borderStyle: 'solid'
 
    },
    ColumnaNormalLeft: {
        fontSize: "9.5px",
        width: "18%",
        textAlign: "left",
        display: "block",
       
        borderLeft:"1px",
        borderBottom:"1px",
        borderStyle: 'solid'
 
    },
    ColumnaNormalClear: {
        fontSize: "9.5px",
        width: "18%",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderLeft:"1px",
        borderStyle: 'solid',
 
    },
    ColumnaPequenaCenterClear: {
        fontSize: "9.5px",
        width: "10%",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderLeft:"1px",
        borderStyle: 'solid',
 
    },
    ColumnaNormalLeftClear: {
        fontSize: "9.5px",
        width: "18%",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderLeft:"1px",
        borderStyle: 'solid',
 
    }
    
    ,
    ColumnaNormalBottom: {
        fontSize: "9.5px",
        width: "15%",
        textAlign: "center",
        display: "block",
        borderStyle: 'solid', 

    },
    ColumnaPequenaCenterBottom: {
        fontSize: "9.5px",
        width: "10%",
        textAlign: "center",
        display: "block",
        borderStyle: 'solid', 

    },
    ColumnaNormalLeftBottom: {
        fontSize: "9.5px",
        width: "15%",
        textAlign: "center",
        display: "block",
        borderStyle: 'solid', 

    },

    ColumnaNormal2Header: {
        fontSize: "9.5px",
        width: "18%",
        textAlign: "center",
        display: "block",
        fontFamily:'Helvetica-Bold',
        borderStyle: 'solid', 

    },
    ColumnaNormal2: {
        fontSize: "9.5px",
        width: "18%",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderLeft:"1px",
        borderBottom:"1px",
        borderStyle: 'solid',
 
    },


    ColumnaNormal2Bottom: {
        fontSize: "9.5px",
        width: "18%",
        textAlign: "center",
        display: "block",
        borderStyle: 'solid', 

    },

    ColumnaNormal2Clear: {
        fontSize: "9.5px",
        width: "18%",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderLeft:"1px",
        borderStyle: 'solid',
    },

    ColumnaGrandeHeader: {
        width: "45%",
        fontSize: "9.5px",
        textAlign: "center",
        display: "block",
        borderLeft:"1px",
        fontFamily:'Helvetica-Bold',
        borderStyle: 'solid', 


    },
    ColumnaGrande: {
        width: "45%",
        fontSize: "9.5px",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderLeft:"1px",
        borderBottom:"1px",
        borderStyle: 'solid',

    },
    ColumnaGrandeClear: {
        width: "45%",
        fontSize: "9.5px",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderLeft:"1px",
        borderStyle: 'solid',
    },
    ColumnaGrandeBottom: {
        width: "45%",
        fontSize: "9.5px",
        textAlign: "center",
        display: "block",
        borderStyle: 'solid', 

    },


    ColumnaGrande2Header: {
        width: "60%",
        fontSize: "9.5px",
        textAlign: "center",
        display: "block",
        fontFamily:'Helvetica-Bold',
        borderStyle: 'solid', 
    },
    ColumnaGrande2: {
        width: "60%",
        fontSize: "9.5px",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderLeft:"1px",
        borderBottom:"1px",
        borderStyle: 'solid',

    },
    ColumnaGrande2Clear: {
        width: "60%",
        fontSize: "9.5px",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderLeft:"1px",
        borderStyle: 'solid',

    },

    ColumnaMonetariaHeader: {
        width: "10%",
        fontSize: "9.5px",
        textAlign: "left",
        display: "block",
        fontFamily:'Helvetica-Bold',
        borderStyle: 'solid', 
        
 
    },
    ColumnaMonetaria: {
        width: "10%",
        fontSize: "9.5px",
        textAlign: "right",
        display: "block",
        borderColor: 'black',
        borderBottom:"1px",
        borderStyle: 'solid',

    },
    ColumnaMonetariaClear: {
        width: "10%",
        fontSize: "9.5px",
        textAlign: "right",
        display: "block",
        borderStyle: 'solid', 

    },
    ColumnaMonetariaBottom: {
        width: "10%",
        fontSize: "9.5px",
        textAlign: "center",
        display: "block",
        borderStyle: 'solid', 
 
    },
    ColumnaMonetariaRightHeader: {
        width: "10%",
        fontSize: "9.5px",
        textAlign: "left",
        display: "block",
        fontFamily:'Helvetica-Bold',
        borderStyle: 'solid', 
        
 
    },
    ColumnaMonetariaRight: {
        width: "10%",
        fontSize: "9.5px",
        textAlign: "right",
        display: "block",
        borderColor: 'black',
        borderRight:"1px",
        borderBottom:"1px",
        borderStyle: 'solid',

    },
    ColumnaMonetariaRightClear: {
        width: "10%",
        fontSize: "9.5px",
        textAlign: "right",
        display: "block",
        borderColor: 'black',
        borderRight:"1px",
        borderStyle: 'solid',

    },

    ColumnaMonetariaRightBottom: {
        width: "10%",
        fontSize: "9.5px",
        textAlign: "center",
        display: "block",
        borderStyle: 'solid', 
 
    },
    SimboloHeader: {
        width: "3.5%",
        fontSize: "9.5px",
        textAlign: "right",
        display: "block",
        borderLeft:"1px",
        fontFamily:'Helvetica-Bold',
        borderStyle: 'solid', 
  
    },
    Simbolo: {
        width: "3.6%",
        fontSize: "9.5px",
        textAlign: "right",
        display: "block",
        borderColor: 'black',
        borderLeft:"1px",
        borderBottom:"1px",
        borderStyle: 'solid',
       
    },
    SimboloClear: {
        width: "3.5%",
        fontSize: "9.5px",
        textAlign: "right",
        display: "block",
        borderColor: 'black',
        borderLeft:"1px",
        borderStyle: 'solid',
       
    },

    SimboloBottom: {
        width: "3.5%",
        fontSize: "9.5px",
        textAlign: "right",
        display: "block",
        borderStyle: 'solid', 
        
    },
    
    CuerpoTabla: {
        width: "100%",
        textAlign: "center",
        fontSize: "9px",
        
//paddingTop: 50
    },
    CuerpoTablaFinal: {
        width: "100%",
        textAlign: "center",
        fontSize: "9px",
        borderColor: 'black',
        borderBottom:"1px",
        borderStyle: 'solid',
        
//paddingTop: 50
    },
    Fila: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        
    }, FilaBreak: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        paddingTop: "105px"
        
    }
    
    
    ,
    ColumnaNormalCenterHeader: {
        fontSize: "9.5px",
        width: "10%",
        textAlign: "center",
        display: "block",
        fontFamily:'Helvetica-Bold',
        borderStyle: 'solid', 
    
    },
    ColumnaNormalCenter: {
        fontSize: "9.5px",
        width: "10%",
        textAlign: "center",
        display: "block",
        borderColor: 'black',
        borderLeft:"1px",
        borderBottom:"1px",
        borderStyle: 'solid',
    },
    ColumnaNormalCenterClear: {
        fontSize: "9.5px",
        width: "10%",
        textAlign: "center",
        display: "block",
        borderColor: 'black',
        borderLeft:"1px",
        borderStyle: 'solid',
        
    },
    ColumnaNormalCenterBottom: {
        fontSize: "9.5px",
        width: "10%",
        textAlign: "center",
        display: "block",
        borderStyle: 'solid', 
    },

    BottomTextStyle:{
        marginTop:"8px",
        fontSize: "8px",
       
    },
    CabeceraBottom: {
        width: "100%",
        backgroundColor: "#FFFFFF",
        color: "#000000",
        textAlign: "center",
        display: "flex",
        fontSize:"12px",
        flexDirection: "row",
        marginTop:"4px"
    },

    ColumnButtom: {
        width: "12.5%",
        textAlign: "center",
        display: "block",
    },

    NormalButtom: {
        marginTop:"2px",
        width: "7%",
        fontSize: "9px",
        textAlign: "center",
        display: "block",  
    },
    LargeButtom: {
        marginTop:"2px",
        width: "23%",
        fontSize: "9px",
        textAlign: "left",
        display: "block"
    },
    CheckButtom: {
        width: "4%",
        minHeight:"15px",
        fontSize: "9px",
        textAlign: "center",
        display: "block",
        border: '1px',
        borderColor: 'black',
        marginLeft:"2px",
        marginRight:"2px", 
        borderStyle: 'solid', 
    },
    LineButtom: {
        marginLeft:"2px",
        marginRight:"2px",
        marginTop:"2px",
        width: "20%",
        fontSize: "9px",
        textAlign: "center",
        display: "block",
        borderBottom:"1px",
        borderColor: 'black',
        borderStyle: 'solid',    
    },
    BottomMargin: {
        width:"100%",
        marginTop:"10px",
        minHeight:"50px",
        
    },
    LineButtom2: {
    
        width: "20%",
        fontSize: "9px",
        textAlign: "right",
        display: "block",   
    },
    LineButtom3: {
 
        width: "20%",
        fontSize: "9px",
        textAlign: "right",
        display: "block",   
        borderTop:"1px",
        borderColor: 'black',
        borderStyle: 'solid', 
    },
    LineButtom2Left: {

        width: "18%",
        fontSize: "9px",
        textAlign: "left",
        display: "block",   
    },
    LineButtom3Left: {
        
        width: "18%",
        fontSize: "9px",
        textAlign: "left",
        display: "block",
        borderTop:"1px",
        borderColor: 'black',
        borderStyle: 'solid',     
    },
    
    
    DolarButtomLeft: {

        width: "2%",
        fontSize: "9px",
        textAlign: "left",
        display: "block",   
    },
    DolarButtomLeft2: {
        
        width: "2%",
        fontSize: "9px",
        textAlign: "left",
        display: "block",
        borderTop:"1px",
        borderColor: 'black',
        borderStyle: 'solid',      
    }
    ,
    ClearBottom: {
        marginLeft:"1px",
        marginRight:"1px",
        marginTop:"1px",
        width: "9.5%",
        fontSize: "9px",
        textAlign: "right",
        display: "block",   
        borderStyle: 'solid',
    },
    ColumnaNormal2LeftHeader: {
        fontSize: "7.5px",
        width: "18%",
        textAlign: "center",
        display: "block",
        fontFamily:'Helvetica-Bold'

    },
    ColumnaNormal2Left: {
        fontSize: "7.5px",
        width: "18%",
        textAlign: "left",
        display: "block",
        borderColor: 'black',
        borderLeft:"1px",
        borderBottom:"1px",
        borderStyle: 'solid',
 
    },


    ColumnaNormal2LeftBottom: {
        fontSize: "7.5px",
        width: "18%",
        textAlign: "center",
        display: "block",

    },

});

export default styles;
import React, { Component } from 'react'
import { BsArrowRepeat } from "react-icons/bs";
import { getInformationWithData, getDetailSO } from '../../../services/CABE';
import "../../../css/inventory-style.css"
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import LoadingWindow from '../../../components/LoadingWindow';
import { getIMGByItemCode, ItemDesc, NumberFormat, OrderArray, random_rgba } from '../../../functions/generalFunctions';
import { pricesFormat } from '../../../functions/pricesFormat';
import { DateFormatMAS90, getDateFromReports, OrderArrayByDateGenericLinesDesc } from '../../../functions/dateFormat';
import { getIndexElement } from '../../../functions/searchInObject';
import ModalOrders from '../../../components/ModalComponent';
import ExcelDocument from '../../../components/ExcelDocument';
import { getValueCookie } from '../../../services/cookieService';
import { getDataSet } from '../../../functions/generateDataSetExcel';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement, Filler } from 'chart.js';
import { generateSOReport } from '../../../utils/SalesOrderReport';


export default class NotPostedInvoicesReport extends Component {
  constructor(props) {
    super(props)
    this.ModalLoading = React.createRef();
  }

    state = {
      invoices: [],
      products: []
    }

  async getInvoices(){
    const data = {
        idCompany: Number(getValueCookie('CompanyId')),
        SalespersonNo: getValueCookie('SalesPerson'),
        CustomerNo: "*"
    }
    this.ModalLoading.current.showState(true);
    const result = await getInformationWithData('/invoice/header', data)
    if (result.status.code === 1) {
        const temporal = result.data;
        this.setState({ invoices: temporal })
        let currentInvoices = temporal;
        currentInvoices.forEach(invoice => {
          this.getSaleOrder(invoice);
        });
    }
    this.ModalLoading.current.showState(false);
  }
  
  // Get items from the invoices
  async getSaleOrder(invoice){
    console.log(invoice)
      const orderData = {
        idCompany: Number(getValueCookie('CompanyId')),
        OrderNo: invoice.SalesOrderNo
    }
    const getInvoiceDetail = await getInformationWithData('/sales/get/detail', orderData)
    if (getInvoiceDetail.status.code ===1) {
      const products = getInvoiceDetail.data.Tabla2
      this.setState((prevState) => {
        let updatedProducts = [...prevState.products];

        // Check if a product is already on the array
        products.forEach(item => {
          const isProductExistIndex = updatedProducts.findIndex(
            (product) => product.ItemCode === item.ItemCode 
          );

          if(isProductExistIndex !== -1){
            updatedProducts[isProductExistIndex].QuantityShipped += item.QuantityShipped;
          } else {
            updatedProducts.push(item)
          }
        });
        return {products: updatedProducts};
      })
    }
    
  }

  render() {
    return (
      <div className='container'>
        <LoadingWindow ref={this.ModalLoading} />
        <p className='text-center display-1 pb-2' >Report Not Posted Invoices</p>
        <button onClick={() => this.getInvoices()} type="button" className="btn blueButton btn-lg">Load Report <BsArrowRepeat /></button>

        {
          // Render not posted invoices
          this.state.invoices.length > 0?
          <div>
            <table class="table">
              <thead class="table-dark">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Order</th>
                  <th scope="col">Customer No</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Invoice date</th>
                  <th scope="col">Sales Person</th>
                  <th scope="col">Total Amt</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.invoices.map((invoice, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <th>{invoice.InvoiceNo}</th>
                  <th>{invoice.CustomerNo}</th>
                  <th>{invoice.BillToName}</th>
                  <th>{DateFormatMAS90(invoice.InvoiceDate)}</th>
                  <th>{invoice.SalespersonNo}</th>
                  <th>$ {invoice.NonTaxableAmt}</th>
                </tr>
                  ))
                }
              </tbody>
            </table>
            <br />
            <table class="table">
              <thead class="table-dark">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Item Code</th>
                  <th scope="col">Product Line</th>
                  <th scope="col">Description</th>
                  <th scope="col">Total Ordered</th>
                  <th scope="col">Total Amt</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.products.map((product, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <th>{product.ItemCode}</th>
                  <th>{product.CustomerNo}</th>
                  <th>{product.ItemCodeDesc}</th>
                  <th>{product.QuantityShipped}</th>
                  <th>{NumberFormat(pricesFormat(product.UnitPrice * product.QuantityShipped))}</th>
                </tr>
                  ))
                }
              </tbody>
            </table>

          </div>
          : <p>There is no invoices to show</p>
        }

        {
        // Render items from not posted invoices

        }
      </div>
    )
  }
}
import { closeAllPages, openPage } from "./changeVisibility";
import { removeCookie } from "../services/cookieService";
import { v4 as uuidv4 } from "uuid";
import { getInformationWithDataGlobalSales } from "../services/CABE";

export function CloseAllPages() {
  closeAllPages();
}

export async function OpenCompanyDashBoard() {
  CloseAllPages();
  var boton = document.getElementById("catalogueOfCompanyActioner");
  await boton.click();
  openPage("App");
  openPage("CompanyDashBoard");
}

export async function GetNumberOfOrders(SalesPerson) {
  const respuesta = await getInformationWithDataGlobalSales("/Sales/get", {
    SalespersonNo: SalesPerson,
  });
  if (respuesta.SalesOrderList) {
    return respuesta.SalesOrderList.length;
  }
  return 0;
}

export async function OpenLobby() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("Lobby");
  var boton = document.getElementById("LobbyInfoCharge");
  if (boton) {
    await boton.click();
  }

  //OpenPikingSystem()
}

export function PriceMargin() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("MargenesPrecio");
}

export async function OpenCycleInventory() {
  CloseAllPages();
  var boton = document.getElementById("actionatorCycleInventory");
  await boton.click();
  openPage("App");
  openPage("GeneralPages");
  openPage("CycleInventory");
}

export function OpenCycleInventoryGraph() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("CycleInventoryGraph");
}

export function OpenInventoryPromotionsView() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("PromotionsView");
}

export async function OpenKPIGraph() {
  CloseAllPages();
  var boton = document.getElementById("actionatorKPI");
  await boton.click();
  openPage("App");
  openPage("GeneralPages");
  openPage("KPIGraph");
}

export function OpenLogin() {
  CloseAllPages();
  openPage("login");
}

export function OpenInventory() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("Inventory");
}

export function OpenPikingSystem() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("PikingSystem");
}

export function OpenWasteSystem() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("WasteSystem");
}

export function OpenGeneralPages(page) {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage(page);
}

export function OpenSalesOrder() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("SalesOrder");
}

export function OpenPortalSalesOrder() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("PortalSalesOrder");
}

export async function OpenPurchaseOrder() {
  CloseAllPages();
  var button = document.getElementById("vendorsFromPurchaseOrder");
  await button.click();
  openPage("App");
  openPage("GeneralPages");
  openPage("PurchaseOrder");
}

export function OpenNewCustomer() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("NewCustomer");
}

export function OpenInvoices() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("Invoices");
}

export function OpenAccountsReceivable() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("AccountsReceivable");
}

export function OpenNotPostedInvoicesReport(){
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("NotPostedInvoicesReport");
}

export function OpenShortsReport() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("ShortsReport");
}

export function OpenMOHReport() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("MOHReport");
}

export function OpenSalesReport() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("SalesReport");
}

export function OpenVendorReport() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("VendorReport");
}

export function OpenPurchaseProcessReport() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("PurchaseProcessReport");
}

export function OpenActualInventoryReport() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("ActualInventoryReport");
}

export function OpenHistoryInventoryReport() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("HistoryInventoryReport");
}

export function OpenDesignPage() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("DesignPage");
}

export function OpenAutomaticPOPage() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("AutomaticPOPage");
}

export function OpenReturnPurchaseOrder() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("ReturnPurchaseOrder");
}

export function OpenPriceMarginProdLine() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("PriceMarginProdLine");
}

export function OpenNewProductsToInclude() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("NewProductsToInclude");
}

export function OpenPaymentRecord() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("PaymentRecord");
}

export function OpenScanningReport() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("ScanningReport");
}

export function OpenTrackingReport() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("TrackingReport");
}

export function OpenWeightPerTravelReport() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("WeightPerTravelReport");
}

export function OpenHistoryPurchaseOrdersReport() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("HistoryPurchaseOrdersReport");
}

export function OpenSalesOrdersReport() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("SalesOrderReport");
}

export function OpenVendorPriceChangeReport() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("VendorPriceChangeReport");
}

export function OpenSpoilageReport() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("SpoilageReport");
}

export function OpenRouteSales() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("RouteSalesReport");
}

export function OpenCalculateMargins() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("CalculateMargin");
}

export function OpenInventoryOverstock() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("InventoryOverstock");
}

export function OpenGeneralMarginPrices() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("GeneralMarginPrices");
}

export function OpenUsersManagement() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("UsersManagement");
}

export function OpenLogs() {
  CloseAllPages();
  openPage("App");
  openPage("GeneralPages");
  openPage("LogsPage")
}

export async function OpenPurchasing() {
  CloseAllPages();
  var boton = document.getElementById("actionatorPurchasing");
  await boton.click();
  openPage("App");
  openPage("GeneralPages");
  openPage("Purchasing");
}

export function CloseCompanySession() {
  removeCookie("Company");
  removeCookie("SubCompanyId");
  removeCookie("TypeUser");
  removeCookie("SalesPerson");
  removeCookie("CompanyId");
  removeCookie("phone");
  removeCookie("warehouse");
  removeCookie("website");
  removeCookie("address");
  removeCookie("fullName");
  removeCookie("DocumentsLogo");
  removeCookie("CompanyLogo");
  removeCookie("TotalOrders");
  window.localStorage.setItem("session", uuidv4());
  OpenCompanyDashBoard();
}

export default {
  OpenLogin,
  CloseAllPages,
  OpenCycleInventory,
  OpenLobby,
  OpenCompanyDashBoard,
  OpenCycleInventoryGraph,
  CloseCompanySession,
  OpenSalesOrder,
  OpenInvoices,
  OpenAccountsReceivable,
  OpenNotPostedInvoicesReport,
  OpenShortsReport,
  OpenMOHReport,
  OpenSalesReport,
  OpenVendorReport,
  OpenPurchaseProcessReport,
  OpenDesignPage,
  OpenActualInventoryReport,
  OpenHistoryInventoryReport,
  OpenAutomaticPOPage,
  OpenNewProductsToInclude,
  OpenPaymentRecord,
  OpenScanningReport,
  OpenTrackingReport,
  OpenHistoryPurchaseOrdersReport,
  OpenWeightPerTravelReport,
  OpenReturnPurchaseOrder,
  OpenPurchasing,
  OpenLogs,
};
